import { Controller } from '@hotwired/stimulus';

class SelectController extends Controller {
  static targets = [];

  connect() {
    this.startWatcher();
  }

  disconnect() {
    this.stopWatcher();
  }

  startWatcher = () => {
    this.watch = setInterval(this.onUpdate, 300);
  };

  stopWatcher = () => {
    clearInterval(this.watch);
  };

  onUpdate = () => {
    // On essaie de détruire select2 dès qu'il est disponible
    if (this.hasSelect2()) {
      this.destroySelect2();
      this.stopWatcher();
    }
  };

  hasSelect2() {
    return this.element.classList.contains('select2-hidden-accessible');
  }

  destroySelect2() {
    // On utilise jQuery ici uniquement car select2 dépend entièrement
    $(this.element).select2('destroy');
  }
}

export default SelectController;
