import { Controller } from '@hotwired/stimulus';

class GMapController extends Controller {
  connect = () => {
    if (typeof google === 'undefined') {
      console.warn('google is not defined');
      return;
    }
    this.autocomplete = new google.maps.places.Autocomplete(this.element);
    this.autocomplete.addListener('place_changed', () => {
      fillInAddress(this.autocomplete.getPlace());
    });

    function fillInAddress(place) {
      const { address_components: addressComponents } = place;
      let address = '';
      let city = '';
      let codeIso = '';
      let postalCode = '';

      for (const addressComponent of addressComponents) {
        switch (addressComponent.types[0]) {
          case 'street_number': {
            address += `${addressComponent.long_name} `;

            break;
          }
          case 'route': {
            address += addressComponent.long_name;

            break;
          }
          case 'locality': {
            city = addressComponent.long_name;

            break;
          }
          case 'country': {
            codeIso = addressComponent.short_name;

            break;
          }
          case 'postal_code': {
            postalCode = addressComponent.long_name;

            break;
          }
          // No default
        }
      }

      // FIXME: Ne pas utiliser d'id
      document.querySelector('#customer_address_address_attributes_address').value = address;
      document.querySelector('#customer_address_address_attributes_city').value = city;
      document.querySelector('#customer_address_address_attributes_zip_code').value = postalCode;
      document.querySelector('#customer_address_address_attributes_code_iso').value = codeIso;
      document.querySelector('#customer_address_address_attributes_code_iso').dispatchEvent(new Event('change'));
    }
  };
}

export default GMapController;
