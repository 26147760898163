import { Controller } from '@hotwired/stimulus';

class DragAndDropController extends Controller {
  static targets = ['fileInput'];

  drag(e) {
    e.preventDefault();
    this.element.classList.add('drag-and-drop--over-active');
  }

  drop(e) {
    e.preventDefault();
    this.fileInputTarget.files = e.dataTransfer.files;
    const event = new Event('change');
    this.fileInputTarget.dispatchEvent(event);
    this.element.classList.remove('drag-and-drop--over-active');
  }

  dragleave(e) {
    e.preventDefault();
    this.element.classList.remove('drag-and-drop--over-active');
  }

  trigger(e) {
    if (e.target.tagName !== 'INPUT') {
      this.fileInputTarget.click();
    }
  }
}

export default DragAndDropController;
