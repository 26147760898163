import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

class SubFamilyEventController extends Controller {
  static targets = ["subFamilyId", "familyId", "inGalleryPickup", "picture", "unitPrice", "quantity"]

  changeSubfamily(){
    this.updateElementForSubFamily(this.inGalleryPickupTarget, '/orders/in_gallery_pickup')
    this.updateElementForSubFamily(this.pictureTarget, '/orders/pictures')
  };

  changeFamily(){
    this.updateElementForFamily(this.unitPriceTarget, '/items/unit_price')
    this.updateElementForFamily(this.quantityTarget, '/items/quantity')
  };

  updateElementForSubFamily(target, path){
    get(path, {
      query: {
        target: target.id,
        form_scope: target.getAttribute("data-form-scope"),
        subfamily: this.subFamilyIdTarget.value
      },
      responseKind: "turbo-stream"
    })
  }

  updateElementForFamily(target, path){
    get(path, {
      query: {
        target: target.id,
        gallery_currency: target.getAttribute("data-form-gallery"),
        item_id: target.getAttribute("data-item-id"),
        family: this.familyIdTarget.value
      },
      responseKind: "turbo-stream"
    })
  }
}
export default SubFamilyEventController;
