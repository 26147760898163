import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

class FindProductController extends Controller {
  static targets = ["familySelect",
    "subFamilySelect", "subFamilyId",
    "formatSelect", "formatId",
    "dimensionSelect", "dimensionId",
    "finishingSelect", "finishingId",
    "frameSelect", "frameId",
    "irisSelect", "irisId",
    "backgroundSelect", "backgroundId",
    "effectSelect", "effectId",
    "productSelect", "productId",
    "productIdSelected"
  ];

  connect() { }

  findProduct() {
    const queryParams = this.queryParams()
    this.getRequest('/items/find_product', queryParams)
  }

  queryParams(){
    let queryParams = {
      family: this.familySelectTarget.value
    }
    if (this.hasSubFamilyIdTarget) { queryParams.sub_family = this.subFamilyIdTarget.value }
    if (this.hasFormatIdTarget) { queryParams.shape = this.formatIdTarget.value }
    if (this.hasDimensionIdTarget) { queryParams.dimension = this.dimensionIdTarget.value }
    if (this.hasFinishingIdTarget) { queryParams.finishing = this.finishingIdTarget.value }
    if (this.hasFrameIdTarget) { queryParams.frame = this.frameIdTarget.value }
    if (this.hasIrisIdTarget) { queryParams.iris = this.irisIdTarget.value }
    if (this.hasBackgroundIdTarget) { queryParams.background = this.backgroundIdTarget.value }
    if (this.hasEffectIdTarget) { queryParams.effect = this.effectIdTarget.value }
    if (this.hasProductIdTarget) { queryParams.product = this.productIdTarget.value }

    return queryParams
  }

  getRequest(path, queryParams){
    get(path, {query: queryParams, responseKind: "turbo-stream"})
  }
}

export default FindProductController;
