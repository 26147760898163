// direct_uploads.js
function initializeDirectUpload(event) {
  const { target, detail } = event;
  const { id, file } = detail;
  const directUploadContainer = document.createElement('div');
  directUploadContainer.id = `direct-upload-${id}`;
  directUploadContainer.className = 'direct-upload direct-upload--pending';

  directUploadContainer.innerHTML = `
    <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
    <span class="direct-upload__filename">${file.name}</span>
  `;

  target.before(directUploadContainer);
}

function startDirectUpload(event) {
  const { id } = event.detail;
  const element = document.querySelector(`#direct-upload-${id}`);
  element.classList.remove('direct-upload--pending');
}

function updateDirectUploadProgress(event) {
  const { id, progress } = event.detail;
  const progressElement = document.querySelector(`#direct-upload-progress-${id}`);
  progressElement.style.width = `${progress}%`;
}

function handleDirectUploadError(event) {
  event.preventDefault();
  const { id, error } = event.detail;
  const element = document.querySelector(`#direct-upload-${id}`);
  element.classList.add('direct-upload--error');
  element.setAttribute('title', error);
}

function completeDirectUpload(event) {
  const { id } = event.detail;
  const element = document.querySelector(`#direct-upload-${id}`);
  element.classList.add('direct-upload--complete');
}

document.addEventListener('direct-upload:initialize', initializeDirectUpload);
document.addEventListener('direct-upload:start', startDirectUpload);
document.addEventListener('direct-upload:progress', updateDirectUploadProgress);
document.addEventListener('direct-upload:error', handleDirectUploadError);
document.addEventListener('direct-upload:end', completeDirectUpload);
