import { Controller } from '@hotwired/stimulus';

class QaItemController extends Controller {
  static targets = ['state', 'errorType'];

  handleChange() {
    const selectedErrorType = this.errorTypeTarget.querySelector('select').value;
    const stateSelect = this.stateTarget.querySelector('select');
    const currentState = stateSelect.value;

    if (selectedErrorType !== '' && currentState !== 'refused') {
      stateSelect.value = 'refused';
    } else if (selectedErrorType === '' && currentState !== 'pending') {
      stateSelect.value = 'pending';
    }
  }
}

export default QaItemController;
