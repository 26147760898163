import { Controller } from "@hotwired/stimulus"
import { Modal } from 'flowbite';

export default class extends Controller {
  static targets = ["modal"]

  modalId() {
    let modalId = this.data.get("modalId");
    if (modalId) {
      return modalId;
    }

    return this.modalTarget.id;
  }

  modal() {
    const targetEl = document.getElementById(this.modalId());
    return new Modal(targetEl);
  }

  showModal() {
    if (this.modal()._targetEl.classList.contains('hidden')) {
      this.modal().show();
    }
  }

  // hide modal
  // action: "turbo-modal#hideModal"
  hideModal() {
    // this.element.parentElement.removeAttribute("src")
    // Remove src reference from parent frame element
    // Without this, turbo won't re-open the modal on subsequent click
    this.modal().hide();
  }

  // hide modal on successful form submission
  // action: "turbo:submit-end->turbo-modal#submitEnd"
  submitEnd(e) {
    if (e.detail.success) {
      this.hideModal()
    }
  }

  // hide modal when clicking ESC
  // action: "keyup@window->turbo-modal#closeWithKeyboard"
  closeWithKeyboard(e) {
    if (e.code == "Escape") {
      this.hideModal()
    }
  }

  // hide modal when clicking outside of modal
  // action: "click@window->turbo-modal#closeBackground"
  closeBackground(e) {
    if (e && this.modalTarget.contains(e.target) || this.modalTarget.classList.contains('hidden'))
    {
      return
    }
    this.hideModal()
  }
}
