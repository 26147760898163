import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

class SearchCustomerController extends Controller {
  static targets = ["searchCustomer", "customerResult"]

  searchCustomer(){
    const searchCustomer = this.searchCustomerTarget.value;
    let customerId = this.customerResultTarget.id

    get("/customers", {
      query: {
        form_scope: this.customerResultTarget.getAttribute("data-form-scope"),
        customer_input: customerId,
        customer: searchCustomer,
      },
      responseKind: "turbo-stream"
    })
  }
}
export default SearchCustomerController;
