import { Controller } from "@hotwired/stimulus"

class SyncCountryController extends Controller {
  static targets = ["phoneCountry"]

  addressCountryChanged(event) {
    const addressCountry = event.target.value
    this.updatePhoneCountry(addressCountry)
  }

  updatePhoneCountry(addressCountry) {
    if (this.hasPhoneCountryTarget) {
      this.phoneCountryTarget.value = addressCountry
    }
  }
}

export default SyncCountryController;
