import { Controller } from '@hotwired/stimulus';

class ConfirmPopUpController extends Controller {
  static targets = ["modal", "message", "checkQuantity"];

  affichePopUp(event) {
    if (this.checkQuantityItems() == true) {
      event.preventDefault();
      this.showModal()
    }

    this.messageText = event.currentTarget.dataset.message;
  }

  showModal() {
    this.modalTarget.classList.remove('hidden');
    this.messageTarget.textContent = this.messageText;
  }

  hideModal(event) {
    if (event) event.preventDefault();
    this.modalTarget.classList.add('hidden');
  }

  confirmAction() {
    this.hideModal();
  }

  checkQuantityItems() {
    let showElement = false;
    this.checkQuantityTargets.forEach(input => {
      if (parseInt(input.value) > 1) {
        showElement = true;
      }
    });

    return showElement
  }
}

export default ConfirmPopUpController;


