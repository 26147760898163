import { Application } from '@hotwired/stimulus';
import NestedForm from '@stimulus-components/rails-nested-form'
import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()
const application = Application.start();
application.register('nested-form', NestedForm)
// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

export { application };
